<template lang="pug">
.estimatead
  el-row
    el-col(style="margin-bottom: 1rem;")
      el-date-picker(v-model="pageModel.condition.create_date[0]", align="right", type="date", placeholder="选择开始时间",
        :picker-options="{disabledDate(time) {return time.getTime() > Date.now();}}")
      el-date-picker(v-model="pageModel.condition.create_date[1]", align="right", type="date", placeholder="选择结束时间",
        :picker-options="{disabledDate(time) {return time.getTime() > Date.now();}}")
      </el-date-picker>
      el-button(type="primary", @click="query(1)") 查询
  el-table(:data="estimateadLogs", stripe, v-loading="dataLoading")
    el-table-column(label="时间")
      template(slot-scope="scope")
        span {{$moment(scope.row.create_date).calendar()}}
    el-table-column(prop="report_time", label="报告时间")
    el-table-column(prop="ad_view_count", label="营销平台-广告曝光量")
    el-table-column(prop="ad_click_count", label="营销平台-广告点击量")
    el-table-column(label="营销平台-花费成本（元）")
      template(slot-scope="scope")
        span {{(scope.row.expense_amount).toFixed(2)}}
    el-table-column(prop="revenue_amount", label="营销平台-收入金额（元）")
      template(slot-scope="scope")
        span {{(scope.row.revenue_amount/100000).toFixed(2)}}
    el-table-column(prop="track_click_count", label="广告联盟-点击量")
    el-table-column(label="广告联盟-CPC")
      template(slot-scope="scope")
        span {{(scope.row.revenue_amount/100000 / scope.row.track_click_count).toFixed(2)}}
    el-table-column(prop="track_click_count", label="预测ROI")
      template(slot-scope="scope")
        span(v-if="scope.row.expense_amount === 0") -
        span(v-else) {{(scope.row.revenue_amount/100000 / scope.row.expense_amount).toFixed(2)}}
  el-pagination(
    background,
    :current-page="pageModel.pageNo",
    layout="prev, pager, next, total",
    :total="pageModel.total"
    @current-change="query"
  )
</template>
  
<script>
export default {
  data() {
    return {
      dataLoading: false,
      estimateadLogs: [],
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        condition: {
          create_date: [new Date(), new Date()]
        }
      }
    }
  },
  mounted() {
    this.query(1)
  },
  methods: {
    query(pageNo) {
      this.pageModel.pageNo = pageNo || this.pageModel.pageNo
      this.dataLoading = true
      this.$service.ADService
        .queryEstimate({
          pageNo: this.pageModel.pageNo,
          pageSize: this.pageModel.pageSize,
          condition: this.pageModel.condition
        })
        .then(res => {
          if (res.status === 201) {
            this.pageModel.total = res.data.data.total
            this.estimateadLogs = res.data.data.estimatead_logs
          }
        })
        .finally(() => {
          this.dataLoading = false
        })
    }
  }
}
</script>

<style lang="less" scope>
</style>